import React from 'react';
import cx from "classnames";

const Highlights = props => {
    const className = cx(
        "bg-gray-100 py-4 px-4",
        props.className,
    );
    return (
        <div className={className}>
            {props.data.split('\n').map(item => {
                return <li className={"font-helvetica text-lg mb-2 lg:mb-4 last:mb-0 leading-snug"}>{item}</li>
            })}
        </div>
    )
}
export default Highlights;